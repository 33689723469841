<template>
    <div>
        <b-modal id="cic-modal" centered header-class="border-0 pt-4 pb-0" no-close-on-backdrop :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md" @hidden="$emit('close-modal')">
            <div class="cic__container mb-3 text-center mx-3">
				<h4 class="mb-3 mt-4 text-white">Edit your workspace profile</h4>
                <vue-cropper
					ref="cropper"
					:src="selectedImage"
					:guides="true"
					:view-mode="1"
					:aspect-ratio="1"
					:drag-mode="cropType"
					:auto-crop-area="0.5"
					:background="false"
					:rotatable="false"
					:scalable="false"
					:zoomable="false"
					:container-style="containerStyle"
					@cropend="onCropEnd"
				></vue-cropper>
				<div class="cic__action d-flex justify-content-center mt-3">
					<button class="btn btn-cancel" @click="$emit('close-modal'), $bvModal.hide('cic-modal')">Cancel</button>
					<button class="btn btn-submit" @click="cropImage">Choose Image</button>
				</div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
	components : {
		VueCropper
	},

	data() {
		return {
			cropType : 'crop',
			containerStyle: {
				height: '350px',
			},
			cropperStyle: {
				borderRadius: '50%',
				overflow: 'hidden'
			},
		}
	},

	props : {
		selectedImage : {
			type : String,
			default : null
		},
	},

	methods : {
		onCropEnd(event) {
			console.log(event.detail);
		},

		cropImage() {
			const cropper = this.$refs.cropper;
			if(cropper){
				const croppedCanvas = cropper.getCroppedCanvas();
				const croppedImage = croppedCanvas.toDataURL('image/jpeg');
				this.$emit('croped-img', croppedImage)
			}
		},
	}
}
</script>

<style scoped>

.btn-cancel, .btn-submit {
  border-radius: 6px;
  font-size: 15px;
  font-weight: 600;
  transition: background-color 0.25s;
  color: white;
}

.btn-cancel {
  background-color: #2D2D2D;
}

.btn-cancel:hover {
  background-color: #2C2C2C;
}

.btn-submit {
  background-color: #6D75F6;
}

.btn-submit:hover {
  background-color: #545EED;
}

.btn-submit[disabled] {
  background-color: #1F1F1F;
  color: #585859;
}

.cic__action {
	gap: 10px;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#cic-modal .modal-content{
    background-color: #222222;
	border-radius: 14px;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
    box-shadow: none !important;
}

.cic__container .cropper-container {
	border-radius: 10px;
}

.cic__container .cropper-view-box,
.cic__container .cropper-face {
  border-radius: 50%;
}
</style>