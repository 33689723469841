<template>
	<section class="create-community">
		<header class="cc__header">
			<img src="@/assets/images/icons/ic_back_nav.svg" alt="back" class="mr-2 cursor-pointer" @click="$router.back()">
		</header>
		<main class="cc">
			<div class="cc__inner">
				<h2 class="cc__title">Create Workspace</h2>
				<p class="cc__subtitle mt-3 mb-4">Your workspace is where you and your friends gather. <br> Create your workspace and start sharing content.</p>
				<input type="file" accept="image/jpeg, image/png" ref="inputImage" style="display: none" @change="handleFileChange">
				<button type="button" class="btn" :class="{ 'btn-ccp' : !cropedImage, 'p-0' : cropedImage }" @click="triggerFileInput">
					<img v-if="!cropedImage" src="@/assets/images/icons/ic_cc_camera.svg" alt="">
					<img v-else :src="cropedImage" :alt="cropedImage" class="cc__selected-image">
				</button>
				<p class="text-white mt-3">Upload image</p>
				<div class="text-left text-white">
					<label for="community-name">Workspace Name</label>
					<input type="text" v-model="community_name" class="cc__form-input form-control" id="community-name" placeholder="Create workspace name">
				</div>
				<div class="cc__actions d-flex align-items-center justify-content-center mt-4">
					<button class="btn cc__btn-cancel" @click="$router.back()">Cancel</button>
					<button class="btn cc__btn-save" :disabled="!community_name" @click="postCommunity">Save</button>
				</div>
			</div>
		</main>
		<Soca v-if="loading" />
		<CropImage :selected-image="selectedImage" @croped-img="handleCropedImage" @close-modal="handleCloseModal"/>
	</section>
</template>

<script>
import { createCommunity } from '@/services/community/community.service'
import { AlertUtils } from "@/mixins/AlertUtils";

import CropImage from '@/components/community/modal/CropImage'
import Soca from '@/components/loading/Soca'

export default {
  mixins: [AlertUtils],
  components : {
	CropImage,
	Soca
  },
  data() {
    return {
      selectedImage: null,
	  cropedImage: null,
	  community_name : null,
	  loading: false,
    };
  },
  methods: {
	triggerFileInput() {
      this.$refs.inputImage.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      const type = event.target.files[0].type;
	  const fileSize = event.target.files[0].size;
      if (type == "image/png" || type === "image/jpeg") {
		if (fileSize > 1000000) {
			this.alertFail(this.$t("file-size-max-1mb"))
		} else {
			this.selectedImage = URL.createObjectURL(file);
			this.$bvModal.show('cic-modal')
		}
      }
    },
	handleCropedImage(val){
		this.cropedImage = val
		this.$bvModal.hide('cic-modal')
	},
	handleCloseModal(){
		this.selectedImage = null
	},
	postCommunity() {
		this.loading = true

		let formData = {
			community_name : this.community_name,
			images_community : this.cropedImage
		}

		createCommunity(formData)
		.then((response) => {
			if(response.status) {
				this.loading = false
				this.$router.push({ name : 'MyAccount', query : { activeTab : 'community' } });
			} else {
				this.loading = false
			}
		})
	},
  }
};
</script>

<style scoped>
.create-community {
	height: 100vh;
}

.cc__header {
	padding: 20px 40px;
	display: flex;
	border-bottom: 1px solid #2D2D2D;
}

.cc {
	--gap: 12px;
	--main-container-padding-top: 80px;
	--main-container-padding-bottom: 40px;
	gap: var(--gap);
	height: calc(
		100vh
		- var(--main-container-padding-top)
		- var(--main-container-padding-bottom)
		- var(--gap)
		- 4px
	);
	display: flex;
	align-items: center;
	justify-content: center;
}

.cc__inner {
	max-width: 500px;
}

.cc__title {
	font-size: 30px;
	font-weight: 900;
	color: #fff;
}

.cc__subtitle {
	font-size: 16px;
	font-weight: 400;
	color: #fff;
}

.cc__form-input {
	height: 38px;
	padding: 10px;
    border-color: #2D2D2D;
    background: transparent;
    box-shadow: none;
	color: #fff;
}

.cc__actions {
	gap: 8px;
}

.btn-ccp {
	width: 94px;
	height: 94px;
	border-radius:50%;
	background-color: #1F1F1F;
	transition: background-color 0.25s;
}

.btn-ccp:hover {
	background-color:#2C2C2C;
}

.cc__btn-cancel, .cc__btn-save {
  border-radius: 4px;
  width: 80px;
  font-size: 15px;
  font-weight: 500;
  transition: background-color 0.25s;
  color: #fff;
}

.cc__btn-cancel {
  background-color: #1F1F1F;
}

.cc__btn-cancel:hover {
  background-color: #2C2C2C;
}

.cc__btn-save {
  background-color: #6D75F6;
}

.cc__btn-save:hover {
  background-color: #545EED;
}

.cc__btn-save[disabled] {
  background-color: #1F1F1F;
  color: #585859;
}

.cc__selected-image {
	width: 94px;
	height: 94px;
	border-radius:50%;
	transition: filter 0.25s;
}

.cc__selected-image:hover {
	filter: brightness(0.6);
}
</style>