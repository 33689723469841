<template>
	<section class="nmc">
		<div class="nmc__wrapper">
			<transition-group name="card" tag="div" class="list__nmc d-flex align-items-center justify-content-center h-100 overflow-auto">
				<div v-for="(data, i) in displayedCards" :key="i" class="card__nmc d-flex flex-column justify-content-center align-items-center">
					<h2>You are invited <br> to join with</h2>
					<img v-if="data.data_community[0].community_picture" :src="data.data_community[0].community_picture" :alt="data.data_community[0].community_name">
					<img v-else :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data.data_community[0].community_name" :alt="data.data_community[0].community_name">
					<h4 class="text-white w-100 text-truncate">{{ data.data_community[0].community_name }}</h4>
					<div class="nmc__action d-flex flex-column justify-content-center align-items-center w-100 mt-4">
						<button class="btn button-join" @click="join(data.community_id)">Join now</button>
						<button class="btn button-ml" @click="rejectInvitation(i)">Maybe later</button>
					</div>
				</div>
			</transition-group>
			<div class="copyright">
				<small style="color:#A6A6A6;">Copyright &copy; 2024 Soca.ai All Reserved</small>
			</div>
		</div>
	</section>
</template>

<script>
import { getListInvitationCommunity, joinCommunity } from '@/services/community/community.service'

export default {
	data() {
		return {
			cards: [
				{ id: 1, name: 'Al-Masoem-Masoem-Masoem-Masoem-Masoem-Masoem', show: true },
				{ id: 2, name: 'Card 2', show: true },
				{ id: 3, name: 'Card 3', show: true },
				{ id: 4, name: 'Card 4', show: false },
				{ id: 5, name: 'Card 5', show: false }
			],

			list_invitations: [],
			loading: false,
		}
	},

	computed: {
		displayedCards() {
			return this.list_invitations.slice(0, 3); // Mengambil 3 data pertama
		}
	},

	mounted() {
		this.getListInvitation()
	},

	methods: {
		rejectInvitation(index) {
			this.list_invitations.splice(index, 1);
			if(this.list_invitations.length == 0){
				this.$router.push({ name : 'Home' })
			}
		},

		getListInvitation(){
			this.loading = true;

			getListInvitationCommunity()
			.then((response) => {
				if(response.status) {
					this.list_invitations = response.data
					this.loading = false
				} else {
					this.list_invitations = []
					this.$router.push({ name : 'MyAccount', query : { activeTab : 'community' } })
					this.loading = false
				}
			})
			.catch((err) => {
				this.loading = false
				console.log(err)
			})
		},

		join(id) {
			let formData = {
				community_id : id
			}

			joinCommunity(formData)
			.then((response) => {
				if(response.status){
					this.getListInvitation()
				}
			})
		}
	}
}
</script>

<style scoped>
.nmc {
	height: 100vh;
}

.nmc__wrapper {
	width: 100%;
	height: 100%;
}

.list__nmc {
	gap: 20px;
}

.card__nmc {
	background: #1F1F1F;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	gap: 10px;
	padding: 40px 20px;
	width: 300px;
	transition: opacity 0.5s;
}

.card__nmc h2 {
	font-weight: 900;
	font-size: 30px;
	color: white;
}

.card__nmc img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
}

.nmc__action {
	gap: 10px;
}

.button-join, .button-ml {
	width: 100%;
	padding: 10px;
	color: white;
	box-shadow: none;
}

.button-ml {
	font-weight: 400;
	font-size: 14px;
}

.button-join {
	font-weight: 600;
	font-size: 16px;
	transition: background-color 0.25s;
	background-color: #6D75F6;
}

.button-join:hover {
	background-color: #545EED;
}

.copyright {
	position: absolute;
	left: 0;
    right: 0;
    bottom: 10px;
}

.card-enter-active,
.card-leave-active {
  transition: opacity 0.5s;
}

.card-enter,
.card-leave-to {
  opacity: 0;
}
</style>